.center {
    text-align: center;
}
.ql-container{
    min-height: 250px;
}
.ql-container2 {
    min-height: 292px;
    position: relative;
}
.ql-container2 textarea.form-control {
    box-sizing: border-box;         /* For IE and modern versions of Chrome */
    -moz-box-sizing: border-box;    /* For Firefox                          */
    -webkit-box-sizing: border-box; /* For Safari                           */
    display:block;/*reset from inline*/
    width: 100%;
    height: 100%;
    position:absolute; top:0; left:0; right:0; bottom:0;
}
.table td.buttons-cell {
    padding: 0;
    vertical-align: middle;
}
.page-item .page-link {
    font-weight: 900;
}
.page-item .page-dots {
    line-height: 1.25;
    padding: calc(1rem + 4px) 0.3rem 0 0.3rem;
    color: #c8ced3;
}
.table .small-tr td{
    padding: 0;
    font-size: 90%;
}
.no-border {
    border: none !important;
}
.dropdown-item.btn-sm {
    padding: 0.20rem 0.5rem;
    font-size: 0.76562rem;
}
.btn.btn-ssm, .btn-group-ssm > .btn {
    padding: 0.20rem 0.45rem;
    font-size: 0.7rem;
    line-height: 1.3;
    border-radius: 0.2rem;
}
.small-tr {
    vertical-align: middle;
}
.attachments {
    padding: 0.25rem 0;
    margin-bottom: 0.1rem;
    border-top: 1px solid #f3f4f5;
    border-bottom: 2px solid #f3f4f5
}

.attachments .attachment {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-item-align: center;
    align-self: center;
    margin: .5rem 0;
    font-size: 12px
}
.attachments .attachment .badge {
    margin: 0 .5rem;
    line-height: inherit
}
.attachments .attachment .menu {
    margin-left: auto
}
.attachments .attachment .menu a {
    padding: 0 .5rem;
    font-size: 14px;
    color: #23282c
}
.attachments .attachment .menu .btn-attachment {
    padding: 0;
    line-height: 1;
}
.input-file[type=file] {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}
.error-message {
  color: #f86c6b;
}
.va-m {
    vertical-align: middle !important;
}
.d-ib {
    display: inline-block;
}
.fs-90 {
    font-size: 90%;
}
.table th {
    padding: 0.75rem 0.5rem;
}
.table td {
    padding: 0.45rem;
    line-height: 1.35;
    vertical-align: middle;
}
.invalid-feedback {
    display: block;
}
.quill.invalid {
    border: 1px solid #f86c6b;
}
.top-grid-tr td{
    border: none;
}
.nav-tabs .nav-link.active:hover {
    background-color: #1b8eb7;
    color: #fff;
}
.nav-tabs .nav-link.active {
    color: #fff;
    background-color: #20a8d8;
}
.nav-tabs .nav-link {
    color: #c8ced3;
}
.settings-card .nav-tabs .nav-link {
    text-transform: uppercase;
}
.form-group .css-dtmiix:hover {
    border-color: #e4e7ea;
}

.form-group .select2-cms:nth-child(1), .form-group .select2-cms:nth-child(0), .form-group .select2-cms:nth-child(2) {
    border-color: #e4e7ea !important;
    box-shadow: none !important;
}
.input-group > .select2-cms {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}
.upload-area{
    margin: 0 auto;
    text-align: center;
    padding: 25px 70px;
    border: 2px dashed #ccc;
    width: auto;
    display: inline-block;
    max-width: 300px;
}
.upload-area img{
    width: 100%;
    max-width: 100%;
}
.upload-area-wrapper:hover .upload-area {
    border-color: #999;
}
.input-file-wrapper:hover .btn-primary{
    background-color: #1b8eb7;
    border-color: #1985ac;
}
.input-file-wrapper img{
    opacity: 0.9;
}
.input-file-wrapper:hover img{
    opacity: 1;
}
.attachment-error {
    color: #f86c6b;
}
table.table .form-group {
    margin: 0;
}

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
    position: relative;
}
.rdtPicker {
    display: none;
    position: absolute;
    width: 250px;
    padding: 4px;
    margin-top: 1px;
    z-index: 99999 !important;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,.1);
    border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
    display: block;
}
.rdtStatic .rdtPicker {
    box-shadow: none;
    position: static;
}

.rdtPicker .rdtTimeToggle {
    text-align: center;
}

.rdtPicker table {
    width: 100%;
    margin: 0;
}
.rdtPicker td,
.rdtPicker th {
    text-align: center;
    height: 28px;
}
.rdtPicker td {
    cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
    background: #eeeeee;
    cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
    color: #999999;
}
.rdtPicker td.rdtToday {
    position: relative;
}
.rdtPicker td.rdtToday:before {
    content: '';
    display: inline-block;
    border-left: 7px solid transparent;
    border-bottom: 7px solid #428bca;
    border-top-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 4px;
    right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
    background-color: #428bca;
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
    border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
    color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}
.rdtPicker th {
    border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
    width: 14.2857%;
    border-bottom: none;
    cursor: default;
}
.rdtPicker th.rdtSwitch {
    width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
    font-size: 21px;
    vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
    display: block;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
    background: none;
    color: #999999;
    cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
    cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
    background: #eeeeee;
}

.rdtPicker tfoot {
    border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
    border: none;
    background: none;
    cursor: pointer;
}
.rdtPicker button:hover {
    background-color: #eee;
}

.rdtPicker thead button {
    width: 100%;
    height: 100%;
}

td.rdtMonth,
td.rdtYear {
    height: 50px;
    width: 25%;
    cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
    background: #eee;
}

.rdtCounters {
    display: inline-block;
}

.rdtCounters > div {
    float: left;
}

.rdtCounter {
    height: 100px;
}

.rdtCounter {
    width: 40px;
}

.rdtCounterSeparator {
    line-height: 100px;
}

.rdtCounter .rdtBtn {
    height: 40%;
    line-height: 40px;
    cursor: pointer;
    display: block;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none;   /* Chrome/Safari/Opera */
    -khtml-user-select: none;    /* Konqueror */
    -moz-user-select: none;      /* Firefox */
    -ms-user-select: none;       /* Internet Explorer/Edge */
    user-select: none;
}
.rdtCounter .rdtBtn:hover {
    background: #eee;
}
.rdtCounter .rdtCount {
    height: 20%;
    font-size: 1.2em;
}

.rdtMilli {
    vertical-align: middle;
    padding-left: 8px;
    width: 48px;
}

.rdtMilli input {
    width: 100%;
    font-size: 1.2em;
    margin-top: 37px;
}

.rdtTime td {
    cursor: default;
}
